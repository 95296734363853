import { all } from "redux-saga/effects";
import { combineReducers } from "redux";
import * as auth from "../app/modules/Auth/_redux/authRedux";
import Users from "./reducers/Users";
import Roles from "./reducers/Roles";
import Permissions from "./reducers/Permissions";
import Common from "./reducers/Common";
import Employees from "./reducers/Employees";
import Salaries from "./reducers/Salaries";
import Deductions from "./reducers/Deductions";
import Bonuses from "./reducers/Bonuses";
import Allowances from "./reducers/Allowances";
import Overtimes from "./reducers/Overtimes";
import saveMonth from "./reducers/Savemonth";
import Departments from "./reducers/Departments";
import Jobs from "./reducers/Jobs";
import LeaveCategories from "./reducers/LeaveCategories";
import Leaves from "./reducers/Leaves";
import Attendances from "./reducers/Attendances";
import WeeklyHours from "./reducers/WeeklyHours";
import TimeSheet from "./reducers/TimeSheet";
import AttendanceConfigs from "./reducers/AttendanceConfigs";
import Payrolls from "./reducers/Payrolls";
import Logs from "./reducers/Logs";
import Branches from "./reducers/Branches";
import TreeStructure from "./reducers/TreeStructure";
import Projects from "./reducers/Projects";
import Proposals from "./reducers/Proposals";
import ProjectTasks from "./reducers/ProjectTasks";
import Tasks from "./reducers/Tasks";

// import Uploads from "./reducers/UploadsIdes";
import DocmentIdes from "./reducers/DocmentIdes";
import Forms from "./reducers/Forms";
export const rootReducer = combineReducers({
  auth: auth.reducer,
  users: Users,
  common: Common,
  roles: Roles,
  permissions: Permissions,
  employees: Employees,
  deductions: Deductions,
  salaries: Salaries,
  payrolls: Payrolls,
  bonuses: Bonuses,
  allowances: Allowances,
  overtimes: Overtimes,
  month: saveMonth,
  departments: Departments,
  jobs: Jobs,
  branches: Branches,
  leavesCategories: LeaveCategories,
  leaves: Leaves,
  attendances: Attendances,
  weeklyHours: WeeklyHours,
  timeSheet: TimeSheet,
  attendanceConfigs: AttendanceConfigs,
  logs: Logs,
  tree: TreeStructure,
  projects: Projects,
  proposals: Proposals,
  projectTasks: ProjectTasks,
  tasks: Tasks,
  uploads_ides: DocmentIdes,
  form_data: Forms,
});

export function* rootSaga() {
  yield all([auth.saga()]);
}
