/* eslint-disable jsx-a11y/role-supports-aria-props */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { useLocation } from "react-router";
import { NavLink } from "react-router-dom";
import { useSelector } from "react-redux";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl, checkIsActive } from "../../../../_helpers";
import { Icon } from "@material-ui/core";
export function AsideMenuList({ layoutProps }) {
  const location = useLocation();

  const getMenuItemActive = (url, hasSubmenu = false) => {
    return checkIsActive(location, url)
      ? ` ${!hasSubmenu &&
          "menu-item-active"} menu-item-open menu-item-not-hightlighted`
      : "";
  };

  const { isEmployee } = useSelector(({ auth }) => ({
    isEmployee: auth.user?.roles?.includes(2),
  }));

  return (
    <>
      {/* begin::Menu Nav */}
      <ul className={`menu-nav ${layoutProps.ulClasses}`}>
        {!isEmployee ? (
          <>
            {/*begin::1 Level*/}

            <li
              className={`menu-item ${getMenuItemActive("/dashboard", false)}`}
              aria-haspopup="true"
            >
              <NavLink className="menu-link" to="/dashboard">
                <span className="svg-icon menu-icon">
                  <SVG
                    src={toAbsoluteUrl("/media/svg/icons/Design/Layers.svg")}
                  />
                </span>
                <span className="menu-text">Dashboard</span>
              </NavLink>
            </li>
            {/*end::1 Level*/}
            {/*begin devices*/}
            <li
              className={`menu-item ${getMenuItemActive("/devices", false)}`}
              aria-haspopup="true"
            >
              <NavLink className="menu-link" to="/devices">
                <span className="svg-icon menu-icon">
                  <Icon className="fas fa-tablet" />
                </span>
                <span className="menu-text">Devices</span>
              </NavLink>
            </li>
            {/*end devices*/}
            {/* begin::Employees */}

            <li className="menu-section ">
              <h4 className="menu-text">Employees</h4>
              <i className="menu-icon flaticon-more-v2"></i>
            </li>

            {/*begin::1 Employees*/}
            <li
              className={`menu-item menu-item-submenu ${getMenuItemActive(
                "/employees",
                true
              )}`}
              aria-haspopup="true"
              data-menu-toggle="hover"
            >
              <NavLink className="menu-link menu-toggle" to="/employees">
                <span className="svg-icon menu-icon">
                  <Icon className="fas fa-user-tie" />
                </span>
                <span className="menu-text">Employees</span>
                <i className="menu-arrow" />
              </NavLink>
              <div className="menu-submenu ">
                <i className="menu-arrow" />
                <ul className="menu-subnav">
                  <li
                    className="menu-item  menu-item-parent"
                    aria-haspopup="true"
                  >
                    <span className="menu-link">
                      <span className="menu-text">Employees</span>
                    </span>
                  </li>

                  <li
                    className={`menu-item ${getMenuItemActive("/employees")}`}
                    aria-haspopup="true"
                  >
                    <NavLink className="menu-link" to="/employees">
                      <span className="svg-icon menu-icon">
                        <Icon className="fas fa-building" />
                      </span>

                      <span className="menu-text">Employees</span>
                    </NavLink>
                  </li>
                  {/*end:: employees*/}

                  {/*begin::2 documents*/}
                  <li
                    className={`menu-item ${getMenuItemActive(
                      "/employees/documents"
                    )}`}
                    aria-haspopup="true"
                  >
                    <NavLink className="menu-link" to="/employees/documents">
                      <span className="svg-icon menu-icon">
                        <Icon className="fas fa-money-check" />
                      </span>
                      <span className="menu-text">Documents</span>
                    </NavLink>
                  </li>
                  {/*end::2 documents*/}
                </ul>
              </div>
            </li>

            {/*end::employees*/}
            {/*begin forms*/}
            <li
              className={`menu-item ${getMenuItemActive("/forms", false)}`}
              aria-haspopup="true"
            >
              <NavLink className="menu-link" to="/forms">
                <span className="svg-icon menu-icon">
                  <Icon className="fab fa-wpforms" />
                </span>
                <span className="menu-text">Forms</span>
              </NavLink>
            </li>
            {/*end forms*/}

            {/* begin::attendances */}
            <li className="menu-section ">
              <h4 className="menu-text">Attendances</h4>
              <i className="menu-icon flaticon-more-v2"></i>
            </li>
            {/* end:: attendances */}

            {/*begin::1 attendances*/}
            <li
              className={`menu-item menu-item-submenu ${getMenuItemActive(
                "/attendances",
                true
              )}`}
              aria-haspopup="true"
              data-menu-toggle="hover"
            >
              <NavLink className="menu-link menu-toggle" to="/attendances">
                <span className="svg-icon menu-icon">
                  <Icon className="fas fa-cogs" />
                </span>
                <span className="menu-text">Attendances</span>
                <i className="menu-arrow" />
              </NavLink>
              <div className="menu-submenu ">
                <i className="menu-arrow" />
                <ul className="menu-subnav">
                  <li
                    className="menu-item  menu-item-parent"
                    aria-haspopup="true"
                  >
                    <span className="menu-link">
                      <span className="menu-text">Attendances</span>
                    </span>
                  </li>

                  <li
                    className={`menu-item ${getMenuItemActive(
                      "/attendances/attendances"
                    )}`}
                    aria-haspopup="true"
                  >
                    <NavLink
                      className="menu-link"
                      to="/attendances/attendances"
                    >
                      <span className="svg-icon menu-icon">
                        <Icon className="fas fa-building" />
                      </span>

                      <span className="menu-text">Attendances</span>
                    </NavLink>
                  </li>
                  {/*end:: attendances*/}

                  {/*begin::2 weekly-hours*/}
                  <li
                    className={`menu-item ${getMenuItemActive(
                      "/attendances/weekly-hours"
                    )}`}
                    aria-haspopup="true"
                  >
                    <NavLink
                      className="menu-link"
                      to="/attendances/weekly-hours"
                    >
                      <span className="svg-icon menu-icon">
                        <Icon className="fas fa-money-check" />
                      </span>
                      <span className="menu-text">Weekly Hours</span>
                    </NavLink>
                  </li>
                  {/*end::2 weekly-hours*/}

                  {/*begin::2 timesheet*/}
                  <li
                    className={`menu-item ${getMenuItemActive(
                      "/attendances/time-sheet"
                    )}`}
                    aria-haspopup="true"
                  >
                    <NavLink className="menu-link" to="/attendances/time-sheet">
                      <span className="svg-icon menu-icon">
                        <Icon className="fas fa-money-check" />
                      </span>
                      <span className="menu-text">Timesheet</span>
                    </NavLink>
                  </li>
                  {/*end::2 weekly-hours*/}
                  {/*begin::2 CheckInOut*/}
                  <li
                    className={`menu-item ${getMenuItemActive(
                      "/attendances/ckeck-ino-out"
                    )}`}
                    aria-haspopup="true"
                  >
                    <NavLink
                      className="menu-link"
                      to="/attendances/ckeck-ino-out"
                    >
                      <span className="svg-icon menu-icon">
                        <Icon className="fas fa-money-check" />
                      </span>
                      <span className="menu-text">Check In/Out</span>
                    </NavLink>
                  </li>
                  {/*end::2 CheckInOut*/}
                </ul>
              </div>
            </li>
            {/*end::attendances*/}

            {/* begin::Work flow */}
            <li className="menu-section ">
              <h4 className="menu-text">Work Flow</h4>
              <i className="menu-icon fas fa-sitemap"></i>
            </li>
            {/* end:: Work flow */}

            {/*begin::1 Work flow*/}
            <li
              className={`menu-item ${getMenuItemActive("/work-flow", false)}`}
              aria-haspopup="true"
            >
              <NavLink className="menu-link" to="/work-flow">
                <span className="svg-icon menu-icon">
                  <Icon className="fas fa-sitemap" />
                </span>
                <span className="menu-text">Work flow</span>
              </NavLink>
            </li>
            {/*end::Work flow*/}
            {/* begin Requests */}

            <li className="menu-section ">
              <h4 className="menu-text">Requests</h4>
              <i className="menu-icon flaticon-more-v2"></i>
            </li>
            {/* end:: Requests  */}

            <li
              className={`menu-item menu-item-submenu ${getMenuItemActive(
                "/requests",
                true
              )}`}
              aria-haspopup="true"
              data-menu-toggle="hover"
            >
              <NavLink className="menu-link menu-toggle" to="/requests">
                <span className="svg-icon menu-icon">
                  <Icon className="far fa-calendar-minus" />
                </span>
                <span className="menu-text">Requests</span>
                <i className="menu-arrow" />
              </NavLink>
              <div className="menu-submenu ">
                <i className="menu-arrow" />
                <ul className="menu-subnav">
                  {/*begin::1 leaves*/}
                  <li
                    className="menu-item  menu-item-parent"
                    aria-haspopup="true"
                  >
                    <span className="menu-link">
                      <span className="menu-text">Leaves</span>
                    </span>
                  </li>

                  <li
                    className={`menu-item ${getMenuItemActive(
                      "/requests/leaves"
                    )}`}
                    aria-haspopup="true"
                  >
                    <NavLink className="menu-link" to="/requests/leaves">
                      <span className="svg-icon menu-icon">
                        <Icon className="fas fa-sign-out-alt" />
                      </span>
                      <span className="menu-text">Leaves</span>
                    </NavLink>
                  </li>
                  {/*end:: Leaves*/}

                  {/*begin::Travel  */}
                  <li
                    className={`menu-item ${getMenuItemActive(
                      "/requests/Travel"
                    )}`}
                    aria-haspopup="true"
                  >
                    <NavLink className="menu-link" to="/requests/Travel">
                      <span className="svg-icon menu-icon">
                        <Icon className="fas fa-bus" />
                      </span>
                      <span className="menu-text">Travel</span>
                    </NavLink>
                  </li>
                  {/*end::Travel  */}
                </ul>
              </div>
            </li>
            {/*end::Administration*/}
            {/* end Requests */}

            {/* begin::PayRoll */}
            <li className="menu-section ">
              <h4 className="menu-text">PayRoll</h4>
              <i className="menu-icon flaticon-more-v2"></i>
            </li>
            {/* end:: section */}

            {/*begin::1 PayRoll*/}
            <li
              className={`menu-item menu-item-submenu ${getMenuItemActive(
                "/payroll",
                true
              )}`}
              aria-haspopup="true"
              data-menu-toggle="hover"
            >
              <NavLink className="menu-link menu-toggle" to="/payroll">
                <span className="svg-icon menu-icon">
                  <Icon className="fas fa-money-bill-alt" />
                </span>
                <span className="menu-text">PayRoll</span>
                <i className="menu-arrow" />
              </NavLink>
              <div className="menu-submenu ">
                <i className="menu-arrow" />
                <ul className="menu-subnav">
                  <li
                    className="menu-item  menu-item-parent"
                    aria-haspopup="true"
                  >
                    <span className="menu-link">
                      <span className="menu-text">Payslips</span>
                    </span>
                  </li>

                  {/*begin::2 payslips*/}
                  <li
                    className={`menu-item ${getMenuItemActive(
                      "/payroll/payslips"
                    )}`}
                    aria-haspopup="true"
                  >
                    <NavLink className="menu-link" to="/payroll/payslips">
                      <span className="svg-icon menu-icon">
                        <Icon className="fas fa-credit-card" />
                      </span>

                      <span className="menu-text">Payslips</span>
                    </NavLink>
                  </li>
                  {/*end::2 payslips*/}

                  {/*begin::2 Deductions*/}
                  <li
                    className={`menu-item ${getMenuItemActive(
                      "/payroll/deductions"
                    )}`}
                    aria-haspopup="true"
                  >
                    <NavLink className="menu-link" to="/payroll/deductions">
                      <span className="svg-icon menu-icon">
                        <Icon className="fas fa-minus-square" />
                      </span>
                      <span className="menu-text">Deductions</span>
                    </NavLink>
                  </li>
                  {/*end::2 Deductions*/}

                  {/*begin::2 bonuses*/}
                  <li
                    className={`menu-item ${getMenuItemActive(
                      "/payroll/bonuses"
                    )}`}
                    aria-haspopup="true"
                  >
                    <NavLink className="menu-link" to="/payroll/bonuses">
                      <span className="svg-icon menu-icon">
                        <Icon className="fas fa-plus-square" />
                      </span>
                      <span className="menu-text">Bonuses</span>
                    </NavLink>
                  </li>
                  {/*end::2 bonuses*/}

                  {/*begin::2 allowances*/}
                  <li
                    className={`menu-item ${getMenuItemActive(
                      "/payroll/allowances"
                    )}`}
                    aria-haspopup="true"
                  >
                    <NavLink className="menu-link" to="/payroll/allowances">
                      <span className="svg-icon menu-icon">
                        <Icon className="fas fa-money-check" />
                      </span>
                      <span className="menu-text">Allowances</span>
                    </NavLink>
                  </li>
                  {/*end::2 allowances*/}

                   {/*begin::2 overtimes*/}
                   <li
                    className={`menu-item ${getMenuItemActive(
                      "/payroll/overtimes"
                    )}`}
                    aria-haspopup="true"
                  >
                    <NavLink className="menu-link" to="/payroll/overtimes">
                      <span className="svg-icon menu-icon">
                        <Icon className="fas fa-money-check" />
                      </span>
                      <span className="menu-text">Overtimes</span>
                    </NavLink>
                  </li>
                  {/*end::2 overtimes*/}
                </ul>
              </div>
            </li>
            {/*end::1 Deductions*/}

            {/* begin::logs */}
            <li className="menu-section ">
              <h4 className="menu-text">History</h4>
              <i className="menu-icon flaticon-more-v2"></i>
            </li>
            {/* end:: logs */}

            {/*begin::1 logs*/}
            <li
              className={`menu-item ${getMenuItemActive("/logs", false)}`}
              aria-haspopup="true"
            >
              <NavLink className="menu-link" to="/logs">
                <span className="svg-icon menu-icon">
                  <Icon className="fas fa-history" />
                </span>
                <span className="menu-text">History</span>
              </NavLink>
            </li>
            {/*end::logs*/}

            {/* begin::Administration */}
            <li className="menu-section ">
              <h4 className="menu-text">Administration</h4>
              <i className="menu-icon flaticon-more-v2"></i>
            </li>
            {/* end:: Administration */}

            <li
              className={`menu-item menu-item-submenu ${getMenuItemActive(
                "/administration",
                true
              )}`}
              aria-haspopup="true"
              data-menu-toggle="hover"
            >
              <NavLink className="menu-link menu-toggle" to="/administration">
                <span className="svg-icon menu-icon">
                  <Icon className="fas fa-users-cog" />
                </span>
                <span className="menu-text">Administration</span>
                <i className="menu-arrow" />
              </NavLink>
              <div className="menu-submenu ">
                <i className="menu-arrow" />
                <ul className="menu-subnav">
                  {/*begin::1 Users*/}
                  <li
                    className="menu-item  menu-item-parent"
                    aria-haspopup="true"
                  >
                    <span className="menu-link">
                      <span className="menu-text">Users</span>
                    </span>
                  </li>

                  <li
                    className={`menu-item ${getMenuItemActive(
                      "/administration/users"
                    )}`}
                    aria-haspopup="true"
                  >
                    <NavLink className="menu-link" to="/administration/users">
                      <span className="svg-icon menu-icon">
                        <Icon className="fas fa-users" />
                      </span>

                      <span className="menu-text">Users</span>
                    </NavLink>
                  </li>
                  {/*end:: Users*/}

                  {/*begin::Roles*/}
                  <li
                    className={`menu-item ${getMenuItemActive(
                      "/administration/roles"
                    )}`}
                    aria-haspopup="true"
                  >
                    <NavLink className="menu-link" to="/administration/roles">
                      <span className="svg-icon menu-icon">
                        <Icon className="fas fa-user-tag" />
                      </span>
                      <span className="menu-text">Roles</span>
                    </NavLink>
                  </li>
                  {/*end::Roles*/}

                  {/*begin::Permissions*/}
                  <li
                    className={`menu-item ${getMenuItemActive(
                      "/administration/permissions"
                    )}`}
                    aria-haspopup="true"
                  >
                    <NavLink
                      className="menu-link"
                      to="/administration/permissions"
                    >
                      <span className="svg-icon menu-icon">
                        <Icon className="fas fa-user-lock" />
                      </span>
                      <span className="menu-text">Permissions</span>
                    </NavLink>
                  </li>
                  {/*end::Permissions*/}
                </ul>
              </div>
            </li>
            {/*end::Administration*/}

            {/* begin::time-cards */}
            <li className="menu-section ">
              <h4 className="menu-text">Time Cards</h4>
              <i className="menu-icon flaticon-more-v2"></i>
            </li>
            {/* end:: time-cards */}

            <li
              className={`menu-item menu-item-submenu ${getMenuItemActive(
                "/time-cards",
                true
              )}`}
              aria-haspopup="true"
              data-menu-toggle="hover"
            >
              <NavLink className="menu-link menu-toggle" to="/time-cards">
                <span className="svg-icon menu-icon">
                  <Icon className="fas fa-calendar-times" />
                </span>
                <span className="menu-text">Time Cards</span>
                <i className="menu-arrow" />
              </NavLink>
              <div className="menu-submenu ">
                <i className="menu-arrow" />
                <ul className="menu-subnav">
                  {/*begin::1 Users*/}
                  <li
                    className="menu-item  menu-item-parent"
                    aria-haspopup="true"
                  >
                    <span className="menu-link">
                      <span className="menu-text">Projects</span>
                    </span>
                  </li>

                  <li
                    className={`menu-item ${getMenuItemActive(
                      "/time-cards/projects"
                    )}`}
                    aria-haspopup="true"
                  >
                    <NavLink className="menu-link" to="/time-cards/projects">
                      <span className="svg-icon menu-icon">
                        <Icon className="fas fa-project-diagram" />
                      </span>

                      <span className="menu-text">Projects</span>
                    </NavLink>
                  </li>
                  {/*end:: Projects*/}

                  {/*begin::Tasks*/}
                  {/* <li
                    className={`menu-item ${getMenuItemActive(
                      "/time-cards/tasks"
                    )}`}
                    aria-haspopup="true"
                  >
                    <NavLink className="menu-link" to="/time-cards/tasks">
                      <span className="svg-icon menu-icon">
                        <Icon className="fas fa-tasks" />
                      </span>
                      <span className="menu-text">Tasks</span>
                    </NavLink>
                  </li> */}
                  {/*end::Tasks*/}

                  {/*begin::Reports*/}
                  <li
                    className={`menu-item ${getMenuItemActive(
                      "/time-cards/reports"
                    )}`}
                    aria-haspopup="true"
                  >
                    <NavLink className="menu-link" to="/time-cards/reports">
                      <span className="svg-icon menu-icon">
                        <Icon className="fas fa-file-signature" />
                      </span>
                      <span className="menu-text">Reports</span>
                    </NavLink>
                  </li>
                  {/*end::Reports*/}
                </ul>
              </div>
            </li>
            {/*begin proposals*/}
            <li
              className={`menu-item ${getMenuItemActive("/proposals", false)}`}
              aria-haspopup="true"
            >
              <NavLink className="menu-link" to="/proposals">
                <span className="svg-icon menu-icon">
                  <Icon className="fab fa-wpforms" />
                </span>
                <span className="menu-text">Proposals</span>
              </NavLink>
            </li>
            {/*end proposals*/}
            {/*end::Time Cards*/}

            {/* begin::Configuration */}
            <li className="menu-section ">
              <h4 className="menu-text">Configuration</h4>
              <i className="menu-icon flaticon-more-v2"></i>
            </li>
            {/* end:: Configuration */}

            {/*begin::1 departments*/}
            <li
              className={`menu-item menu-item-submenu ${getMenuItemActive(
                "/configuration",
                true
              )}`}
              aria-haspopup="true"
              data-menu-toggle="hover"
            >
              <NavLink className="menu-link menu-toggle" to="/configuration">
                <span className="svg-icon menu-icon">
                  <Icon className="fas fa-cogs" />
                </span>
                <span className="menu-text">Configuration</span>
                <i className="menu-arrow" />
              </NavLink>
              <div className="menu-submenu ">
                <i className="menu-arrow" />
                <ul className="menu-subnav">
                  <li
                    className="menu-item  menu-item-parent"
                    aria-haspopup="true"
                  >
                    <span className="menu-link">
                      <span className="menu-text">Branches</span>
                    </span>
                  </li>

                  {/*begin::branches*/}
                  <li
                    className={`menu-item ${getMenuItemActive(
                      "/configuration/branches"
                    )}`}
                    aria-haspopup="true"
                  >
                    <NavLink className="menu-link" to="/configuration/branches">
                      <span className="svg-icon menu-icon">
                        <Icon className="fas fa-user-md" />
                      </span>
                      <span className="menu-text">Branches</span>
                    </NavLink>
                  </li>
                  {/*end::branches*/}

                  <li
                    className={`menu-item ${getMenuItemActive(
                      "/configuration/departments"
                    )}`}
                    aria-haspopup="true"
                  >
                    <NavLink
                      className="menu-link"
                      to="/configuration/departments"
                    >
                      <span className="svg-icon menu-icon">
                        <Icon className="fas fa-building" />
                      </span>

                      <span className="menu-text">Departments</span>
                    </NavLink>
                  </li>
                  {/*end:: departments*/}

                  {/*begin::Jobs*/}
                  <li
                    className={`menu-item ${getMenuItemActive(
                      "/configuration/jobs"
                    )}`}
                    aria-haspopup="true"
                  >
                    <NavLink className="menu-link" to="/configuration/jobs">
                      <span className="svg-icon menu-icon">
                        <Icon className="fas fa-user-md" />
                      </span>
                      <span className="menu-text">Jobs</span>
                    </NavLink>
                  </li>
                  {/*end::Jobs*/}
                  {/*begin::leave-categories*/}
                  <li
                    className={`menu-item ${getMenuItemActive(
                      "/configuration/leave-categories"
                    )}`}
                    aria-haspopup="true"
                  >
                    <NavLink
                      className="menu-link"
                      to="/configuration/leave-categories"
                    >
                      <span className="svg-icon menu-icon">
                        <Icon className="fas fa-sign-out-alt" />
                      </span>
                      <span className="menu-text">Leave Categories</span>
                    </NavLink>
                  </li>
                  {/*end::leave-categories*/}
                  {/*begin::attendance*/}
                  <li
                    className={`menu-item ${getMenuItemActive(
                      "/configuration/attendance"
                    )}`}
                    aria-haspopup="true"
                  >
                    <NavLink
                      className="menu-link"
                      to="/configuration/attendance"
                    >
                      <span className="svg-icon menu-icon">
                        <Icon className="fas fa-sign-out-alt" />
                      </span>
                      <span className="menu-text">Attendance</span>
                    </NavLink>
                  </li>
                  {/*end::attendance*/}
                </ul>
              </div>
            </li>
            {/*end::Configuration*/}
          </>
        ) : (
          <>
            {" "}
            {/*begin::1 Employee*/}
            <li
              className={`menu-item ${getMenuItemActive(
                "/employee-home",
                false
              )}`}
              aria-haspopup="true"
            >
              <NavLink className="menu-link" to="/employee-home">
                <span className="svg-icon menu-icon">
                  <Icon className="fas fa-user-tie" />
                </span>
                <span className="menu-text">Home</span>
              </NavLink>
            </li>
            <li
              className={`menu-item ${getMenuItemActive(
                "/my-info",
                false
              )}`}
              aria-haspopup="true"
            >
              <NavLink className="menu-link" to="/my-info">
                <span className="svg-icon menu-icon">
                  <Icon className="fas fa-user-tie" />
                </span>
                <span className="menu-text">Personal Info</span>
              </NavLink>
            </li>
            <li
              className={`menu-item ${getMenuItemActive(
                "/my-approvals",
                false
              )}`}
              aria-haspopup="true"
            >
              <NavLink className="menu-link" to="/my-approvals">
                <span className="svg-icon menu-icon">
                  <Icon className="fas fa-user-tie" />
                </span>
                <span className="menu-text">Approvals</span>
              </NavLink>
            </li>
            <li
              className={`menu-item menu-item-submenu ${getMenuItemActive(
                "/my-leaves",
                true
              )}`}
              aria-haspopup="true"
              data-menu-toggle="hover"
            >
              <NavLink className="menu-link menu-toggle" to="/my-leaves">
                <span className="svg-icon menu-icon">
                  <Icon className="far fa-calendar-minus" />
                </span>
                <span className="menu-text">Requests</span>
                <i className="menu-arrow" />
              </NavLink>
              <div className="menu-submenu ">
                <i className="menu-arrow" />
                <ul className="menu-subnav">
                  <li
                    className="menu-item  menu-item-parent"
                    aria-haspopup="true"
                  >
                    <span className="menu-link">
                      <span className="menu-text">Leaves</span>
                    </span>
                  </li>

                  <li
                    className={`menu-item ${getMenuItemActive(
                      "/my-leaves"
                    )}`}
                    aria-haspopup="true"
                  >
                    <NavLink className="menu-link" to="/my-leaves">
                      <span className="svg-icon menu-icon">
                        <Icon className="fas fa-sign-out-alt" />
                      </span>
                      <span className="menu-text">Leaves</span>
                    </NavLink>
                  </li>
                </ul>
              </div>
            </li>
            <li
              className={`menu-item ${getMenuItemActive(
                "/my-payslips",
                false
              )}`}
              aria-haspopup="true"
            >
              <NavLink className="menu-link" to="/my-payslips">
                <span className="svg-icon menu-icon">
                  <Icon className="fas fa-user-tie" />
                </span>
                <span className="menu-text">Payslips</span>
              </NavLink>
            </li>
            <li
              className={`menu-item ${getMenuItemActive(
                "/my-salary",
                false
              )}`}
              aria-haspopup="true"
            >
              <NavLink className="menu-link" to="/my-salary">
                <span className="svg-icon menu-icon">
                  <Icon className="fas fa-user-tie" />
                </span>
                <span className="menu-text">Salary</span>
              </NavLink>
            </li>
            <li
              className={`menu-item menu-item-submenu ${getMenuItemActive(
                "/my-attandance",
                true
              )}`}
              aria-haspopup="true"
              data-menu-toggle="hover"
            >
              <NavLink className="menu-link menu-toggle" to="/my-attandance">
                <span className="svg-icon menu-icon">
                  <Icon className="far fa-calendar-minus" />
                </span>
                <span className="menu-text">My Attendance</span>
                <i className="menu-arrow" />
              </NavLink>
              <div className="menu-submenu ">
                <i className="menu-arrow" />
                <ul className="menu-subnav">
                  <li
                    className="menu-item  menu-item-parent"
                    aria-haspopup="true"
                  >
                    <span className="menu-link">
                      <span className="menu-text">Attendance</span>
                    </span>
                  </li>

                  <li
                    className={`menu-item ${getMenuItemActive(
                      "/my-attandance/attandance"
                    )}`}
                    aria-haspopup="true"
                  >
                    <NavLink className="menu-link" to="/my-attandance/attandance">
                      <span className="svg-icon menu-icon">
                        <Icon className="fas fa-sign-out-alt" />
                      </span>
                      <span className="menu-text">Attendance</span>
                    </NavLink>
                  </li>
                  <li
                    className={`menu-item ${getMenuItemActive(
                      "/my-attandance/weekly-hours"
                    )}`}
                    aria-haspopup="true"
                  >
                    <NavLink className="menu-link" to="/my-attandance/weekly-hours">
                      <span className="svg-icon menu-icon">
                        <Icon className="fas fa-sign-out-alt" />
                      </span>
                      <span className="menu-text">Weekly Hours</span>
                    </NavLink>
                  </li>
                </ul>
              </div>
            </li>
            <li
              className={`menu-item ${getMenuItemActive(
                "/my-tasks",
                false
              )}`}
              aria-haspopup="true"
            >
              <NavLink className="menu-link" to="/my-tasks">
                <span className="svg-icon menu-icon">
                  <Icon className="fas fa-user-tie" />
                </span>
                <span className="menu-text">Time Card</span>
              </NavLink>
            </li>
            <li
              className={`menu-item ${getMenuItemActive(
                "/tasks-history",
                false
              )}`}
              aria-haspopup="true"
            >
              <NavLink className="menu-link" to="/tasks-history">
                <span className="svg-icon menu-icon">
                  <Icon className="fas fa-user-tie" />
                </span>
                <span className="menu-text">Time Card History</span>
              </NavLink>
            </li>
            <li
              className={`menu-item ${getMenuItemActive(
                "/my-history",
                false
              )}`}
              aria-haspopup="true"
            >
              <NavLink className="menu-link" to="/my-history">
                <span className="svg-icon menu-icon">
                  <Icon className="fas fa-user-tie" />
                </span>
                <span className="menu-text">History</span>
              </NavLink>
            </li>
            {/*end::1 Employee*/}
          </>
        )}
      </ul>

      {/* end::Menu Nav */}
    </>
  );
}
